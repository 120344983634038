.network-tree {
  scrollbar-width: thin !important;
}

.network-tree > div:empty {
  display: none;
}

.active-node > .euiFlexItem:has(.tree-node)
{
  padding: 0px 6px 0px 6px;
  border-radius: 10px;
  background-color: #79AAD9;

  & .euiText {
    color: white !important;
  }
}

.euiFlexItem.active-node:has(.tree-node-leaf),
.active-node > .euiFlexItem:has(.tree-node-leaf) {
  padding: 2px 1px 0px 2px;
  border-radius: 9%;
  background-color: #79AAD9;
}