
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

$header_fixed_height: 48px;
$footer_fixed_height: 36px;


body {
    padding-top: $header_fixed_height !important; // fixed header height
}

.main-page {
    min-height: calc(100vh - ($header_fixed_height + $footer_fixed_height));
}

.auth-page {
    margin-top: -$header_fixed_height;
}


.hidden {
    display: none !important;
}

.node-motion {
    transition: all .2s !important;
    overflow-y: hidden !important;
}

.node-active {
    color: #00a39a;
}

.health-active {
    svg {
        animation-name: healthBlinking;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-direction: alternate;
    }
}

.euiFlyoutBody__overflowContent {
    height: 100%;
}

tr.table__row--error {
    background-color: #f8e9e9;

    &:hover {
        background-color: #f9eded;
    }
}


tr.table__row--warning {
    background-color: #fff9e8;

    &:hover {
        background-color: #fffaee;
    }
}

@keyframes healthBlinking {
    0% {
        color: #00a39a;
    }

    100% {
        color: transparent;
    }
}